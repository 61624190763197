let baseUrl = "";   //这里是一个默认的url，可以没有
switch (process.env.VUE_APP_FLAG) {
    case 'development':
        baseUrl = "http://192.168.5.145:5555"  //开发环境urlhttp://localhost:55555/
        break
    case 'test': 
        baseUrl = "https://apilab.honghaosoft.cn"  //测试环境中的url
        break
    case 'production':
        baseUrl = "https://apilab.honghaosoft.cn"   //生产环境url
          
}
 
export default baseUrl;