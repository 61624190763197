import {router} from '@/router/router'
// import { resetRouter } from '@/router/index'
// import store from '@/vuex/index'  
import baseUrl from '@/utils/baseUrl'
import { Toast } from 'vant';
// Toast('提示内容');
// import {
//     stringify
// } from "qs";
// const errStatusMsg = {
//     401: "登录失效或无权限(401)",
//     504: "资源请求超时(504)",
//     500: "服务器异常(500)",
//     502: "服务器异常(502)"
// };
const service = axios.create({
    baseURL: baseUrl, // api 的 base_url
    // timeout: 1000 * 60 * 3, // request timeout
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    }
});
// 请求拦截器
service.interceptors.request.use(
    config => {
        // Do something before request is sent
        // if (store.getters.token) {
        //     // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        //     config.headers['X-Token'] = getToken()
        // }
        // store.commit('loadingIncrement')
        var xtoken = localStorage.getItem("Token")
        config.headers['XToken'] = xtoken?xtoken:'' 
        config.headers['Type'] = 'Yy'
        return config;
    },
    error => {
        // Do something with request error
        console.log(error); // for debug
        Promise.reject(error);
    }
);
// 响应拦截器
service.interceptors.response.use(
    response => {
        // store.commit('loadingDecrement')
        // console.log(response)
     
        if (response.data.code === '0' ) {
            return response.data.data;
        } else if(response.data.code === '404' || response.data.code === '500'){
            
            localStorage.removeItem('Token');
            // store.commit('ADD_RecRouter',[])
            // resetRouter()
            router.push('/login');
            // ELEMENT.Message.error(response.data.msg)
            // return false
        } else {

            // ELEMENT.Message.error(response.data.msg)
            return Promise.reject( response.data);
        }
        
       
    },
    error => {
        return Promise.reject(error);
    }
);
const request = function(url, option) {

    const newOptions = {
        url,
        ...option
    };
    const method = newOptions.method.toLowerCase();
    newOptions.headers = {
      
        "Content-Type": option.dataForm ? "application/x-www-form-urlencoded" : "application/json;charset=UTF-8",
        ...newOptions.headers
    };

    // if ( method === "post" || method === "put" || method === "patch" || method === "delete" ) {
    //     newOptions.data = option.dataForm ?
    //         stringify(newOptions.params, {
    //             indices: false
    //         }) : newOptions.params;
    //     newOptions.method = "post";
    //     newOptions.params = {
    //         _method: method
    //     };
    // }
    return new Promise((resolve, reject) => {
       
        return service(newOptions).then(i => {
                
                resolve(i);
            }).catch(e => {
                // console.log(e)
                reject(e);
            });
    });
};

export default request;

